<template>
<div class="p-fluid" style="margin-top: 10px">
    <div class="p-grid">
        <div class="p-col-6">
            <label for="company_name">Company name:</label>
            <Dropdown v-model="selectedTransportCompany" @change="onTransportCompanySelect()" :options="transportCompany" optionLabel="company_name" :class="{'p-invalid':v_bookingtrans$.transport_id.$error}" />
        </div>
        <div class="p-col-6">
            <label for="driver">Driver:</label>
            <Dropdown v-model="selectedDriver" :options="drivers" @change="onDriverChange()" optionLabel="driver_name"></Dropdown>
        </div>
    </div>
    <div class="p-grid" style="align-items: flex-end;">
        <div class="p-col">
            <label for="book_from">From date:</label>
            <Calendar id="book_from" v-model="transportBookingForm.from_date" :monthNavigator="true" :yearNavigator="true" yearRange="2010:2040" dateFormat="dd-M-yy" style="height: 2.15rem;" :class="{'p-invalid':v_bookingtrans$.from_date.$error || !isBookingDateValid}"/>
        </div>
        <div class="p-col">
            <label for="book_to">To date:</label>
            <Calendar id="book_to" v-model="transportBookingForm.to_date" :monthNavigator="true" :yearNavigator="true" yearRange="2010:2040" dateFormat="dd-M-yy" style="height: 2.15rem;" :class="{'p-invalid':v_bookingtrans$.to_date.$error || !isBookingDateValid}"/>
        </div>
    </div>
    <div class="p-grid">
        <div class="p-col" style="padding-top: 15px; align-self: flex-end">
            <Button v-if="!isEditTransportBooking" label="Save Transport Booking" @click="saveTransportBooking()" class="p-button-success p-button-sm" style="width: 160px;"></Button>
            <Button v-if="isEditTransportBooking" label="Update Transport Booking" @click="updateTransportBooking()" class="p-button-success p-button-sm p-mr-1" style="width: 175px" />
        </div>
    </div>
</div>
</template>

<script>
import { ref, reactive, onMounted, computed } from 'vue'
import { useToast } from "primevue/usetoast";
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import BookingService from '../service/BookingService';
import TransportationService from '../service/TransportationService';

export default {
    props: {
        BookingID: Number
    },
    emits: ['RefreshTransportBooking'],
    setup(props, { emit }) {
        const toast = useToast()
        const transportCompany = ref([])
        const selectedTransportCompany = ref()
        
        let drivers = ref([])
        let selectedDriver = ref()

        let transportBookingForm = reactive({})
        let isEditTransportBooking = ref(false)
        let isBookingDateValid = ref(true)

        const bookingService = ref(new BookingService())
        const transportService = ref(new TransportationService())

        onMounted(() => {
            setTransportCompanyDropdown()
        })

        //=============Method=============
        const setTransportCompanyDropdown = () => {
            transportService.value.getAllCompanyName().then(data => {
                transportCompany.value = data

                if(transportBookingForm.driver_id != '') {
                    selectedTransportCompany.value = transportCompany.value.find(item => item.id == transportBookingForm.transport_id)
                }
            });
        }

        const onTransportCompanySelect = () => {
            if(selectedTransportCompany.value.id) {
                transportBookingForm.transport_id = selectedTransportCompany.value.id
                transportBookingForm.driver_id = null
                getDriver(selectedTransportCompany.value.id)
            }
        }

        const getDriver = (transport_id) => {
            if (transport_id != 0) {
                transportService.value.getDriver(transport_id).then(data => {
                    if(!data.errorResponse) {
                        drivers.value = data
                    }

                    if(transportBookingForm.driver_id != '') {
                        selectedDriver.value = drivers.value.find(item => item.driver_id === transportBookingForm.driver_id)
                    }
                })
            }
        }

        const onDriverChange = () => {
            transportBookingForm.driver_id = selectedDriver.value.driver_id
        }

        const initTransportBookingForm = () => {
            const transport_booking_form = {
                id: '',
                transport_id: '',
                driver_id: null,
                from_date: '',
                to_date: '',
                booking_id: '',
            }

            selectedTransportCompany.value = null
            selectedDriver.value = null

            Object.assign(transportBookingForm, transport_booking_form)
        }

        const saveTransportBooking = () => {
            if(validateForm() && validateBookingDate() && props.BookingID != 0) {
                transportBookingForm.booking_id = props.BookingID

                bookingService.value.addBookingTransport(transportBookingForm).then(data => {
                    if(!data.errorResponse) {
                        if(data.status == 400) {
                            isBookingDateValid.value = false
                            toast.add({severity:'warn', summary: 'Save Error', detail: data.message, life: 4500}); 
                        } else {
                            initTransportBookingForm()
                            v_bookingtrans$.value.$reset();
                            isBookingDateValid.value = true
                            emit('RefreshTransportBooking', props.BookingID)
                            toast.add({severity:'success', summary: 'Success', detail:'Transport booking has been created.', life: 3000}); 
                        }
                    } else {
                        toast.add({severity:'warn', summary: 'Error ' + data.status, detail: data.errorResponse, life: 4500});        
                    }
                })
            }
        }

        const editTransportBooking = (emitData) => {
            isEditTransportBooking.value = true
            initTransportBookingForm()
            
            Object.keys(transportBookingForm).forEach((key)=>{
                transportBookingForm[key] = emitData[key]
            })
            
            transportBookingForm.from_date = new Date(transportBookingForm.from_date)
            transportBookingForm.to_date = new Date(transportBookingForm.to_date)
        
            getDriver(emitData.transport_id)
        }

        const updateTransportBooking = () => {
            if(validateForm() && validateBookingDate() && props.BookingID != 0) {              
                bookingService.value.updateBookingTransport(transportBookingForm.id, transportBookingForm).then(data => {
                    if(!data.errorResponse) {
                        if(data.status == 400) {
                            isBookingDateValid.value = false
                            toast.add({severity:'warn', summary: 'Update Error', detail: data.message, life: 4500}); 
                        } else {
                            v_bookingtrans$.value.$reset();
                            emit('RefreshTransportBooking', props.BookingID)
                            isBookingDateValid.value = true
                            toast.add({severity:'success', summary: 'Success', detail:'Transport booking has been updated.', life: 3000});        
                        }
                    } else {
                        toast.add({severity:'warn', summary: 'Error ' + data.status, detail: data.errorResponse, life: 4500});        
                    }
                })
            }
        }

        //=================Form Validation=================
        const bookingTransportRules = computed(() => {
            return {
                transport_id: { required },
                from_date: { required },
                to_date: { required },
            }
        })
        const v_bookingtrans$ = useVuelidate(bookingTransportRules, transportBookingForm)
        const validateForm = () => {
            v_bookingtrans$.value.$validate();
            if(!v_bookingtrans$.value.$error){
                return true
            } else {
                return false
            }
        }

        const validateBookingDate = () => {
            const booking_from = new Date(transportBookingForm.from_date)
            const booking_to = new Date(transportBookingForm.to_date)
            if(booking_from >= booking_to) {
                isBookingDateValid.value = false
                toast.add({severity:'warn', summary: 'Error', detail: 'Selected date is invalid.', life: 4000}); 
                return false
            } else {
                isBookingDateValid.value = true
                return true
            }
        }

        return {
            transportCompany,
            selectedTransportCompany,
            onTransportCompanySelect,
            saveTransportBooking,

            drivers,
            selectedDriver,
            onDriverChange,

            v_bookingtrans$,
            isBookingDateValid,
            transportBookingForm,
            editTransportBooking,
            isEditTransportBooking,
            updateTransportBooking,
        }
    },
}
</script>

<style lang="scss" scoped>

</style>